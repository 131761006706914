<template>
  <div class="notfound-container d-flex justify-center align-center blue">
    <h1 class="white--text">404</h1>
    <router-link
      to="/"
      class="notfound-container__link text-h6 white--text text-decoration-underline"
    >
      Back to the site
    </router-link>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style lang="scss" scoped>
.notfound-container {
  position: relative;
  height: 100%;

  h1 {
    font-size: 20vw;
    line-height: normal;
    margin-bottom: 0;
  }

  &__link {
    position: absolute;
    left: 0;
    bottom: 10rem;
    text-align: center;
    width: 100%;
  }
}
</style>
